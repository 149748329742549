<template>
  <a-modal
    width="430px"
    :title="$t('page.phonePreview')"
    :visible="phonePreviewModalShow"
    @cancel="handleCancel"
  >
    <iphone class="wow fadeInLeft2" data-wow-delay="0.5s">
      <div class="btn01 wow fadeInLeft2" data-wow-delay="1.5s"></div>
      <div class="btn02 wow fadeInLeft2" data-wow-delay="1.7s"></div>
      <div class="btn03 wow fadeInLeft2" data-wow-delay="1.9s"></div>
      <div class="btn04 wow fadeInRight2" data-wow-delay="1.5s"></div>
      <div class="skin wow fadeInRight2" data-wow-delay="1s">
        <div class="screen wow fadeInUp2" data-wow-delay="1.5s">
          <div class="head">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            <span>预览</span>
            <i class="fa fa-cog" aria-hidden="true"></i>
          </div>
          <div class="peak wow fadeInDown2" data-wow-delay="2s">
            <div class="sound"></div>
            <div class="lens"></div>
            <div class="piece-l">
              <div class="circular-l"></div>
            </div>
            <div class="piece-r">
              <div class="circular-r"></div>
            </div>
          </div>
          <div class="area-l">
            <span id="time"></span>
          </div>
          <div class="area-r">
            <div class="signal">
              <span class="ico01"></span>
              <span class="ico02"></span>
              <span class="ico03"></span>
              <span class="ico04"></span>
            </div>
            <i class="fa fa-feed" aria-hidden="true"></i>
            <i class="fa fa-battery-full" aria-hidden="true"></i>
          </div>
          <div class="talk"></div>
          <!-- contact code begin -->
          <div class="iframe-content">
            <iframe :src="url"/>
          </div>
          <!-- contact code end -->
        </div>
      </div>
    </iphone>
  </a-modal>
</template>

<script>
import 'font-awesome/css/font-awesome.css'

const baseUrl = 'http://shop-test.micro-orthant.com/'
export default {
  props: {},
  data () {
    return {
      url: '',
      phonePreviewModalShow: false
    }
  },
  methods: {
    show (url) {
      this.url = baseUrl + url
      this.phonePreviewModalShow = true
    },
    handleCancel () {
      this.phonePreviewModalShow = false
    }
  }
}
</script>

<style lang="less" scoped>
iphone {
  width: 380px;
  height: 770px;
  border-radius: 55px;
  background: #303030;
  padding: 7px;
  display: table;
  color: #333;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 5px rgba(80, 80, 80, 0.8) inset;
  margin: 0px auto;
  cursor: default;
  position: relative;
}
iphone .skin {
  width: 100%;
  height: 100%;
  border: 1px solid #000;
  border-radius: 50px;
  background: #222;
  padding: 15px;
  box-shadow: 0px 0px 0px 7px rgba(68, 68, 68, 0.3);
}
iphone .screen {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: #fff;
  position: relative;
  overflow: hidden;
}
iphone .head {
  width: 100%;
  height: 90px;
  text-align: center;
  position: absolute;
  padding: 45px 15px 10px 15px;
  border-bottom: 10px solid #f5f5f5;
}
iphone .head span {
  font-size: 16px;
  font-weight: bold;
}
iphone .peak {
  width: 56.28%;
  height: 27px;
  margin: 0px auto;
  border-radius: 0 0 20px 20px;
  background: #222;
  position: relative;
}
iphone .piece-l,
iphone .piece-r {
  width: 10px;
  height: 10px;
  background: #222;
  position: absolute;
}
iphone .piece-l {
  left: -10px;
}
iphone .piece-r {
  right: -10px;
}
iphone .circular-l,
iphone .circular-r {
  width: 10px;
  height: 10px;
  background: #fff;
  position: absolute;
}
iphone .circular-l {
  border-radius: 0 5px 0 0;
}
iphone .circular-r {
  border-radius: 5px 0 0 0;
}
iphone .sound {
  width: 48px;
  height: 6px;
  border-radius: 15px;
  background: #555;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -24px;
  margin-top: -10px;
  box-shadow: 0px 4px 4px 0px #444 inset;
}
iphone .lens {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #2c5487;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: 34px;
  margin-top: -10px;
}
iphone .talk {
  width: 50%;
  height: 6px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 8px;
  left: 50%;
  margin-left: -25%;
}
iphone .area-l,
iphone .area-r {
  width: 70px;
  height: 16px;
  position: absolute;
  top: 6px;
}
iphone .area-l {
  left: 0;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  text-indent: 10px;
  font-family: 'Verdana';
  font-weight: 600;
}
iphone .area-r {
  right: 0;
}
iphone .signal {
  float: left;
  width: 16px;
  height: 9px;
  margin-top: 7px;
  margin-right: 6px;
  margin-left: 4px;
}
iphone .signal span {
  float: left;
  width: 2px;
  margin-left: 2px;
  border-radius: 2px;
  background: #111;
  display: block;
}
iphone .signal span:first-child {
  margin-left: 0;
}
iphone .signal .ico01 {
  height: 3px;
  margin-top: 6px;
}
iphone .signal .ico02 {
  height: 5px;
  margin-top: 4px;
}
iphone .signal .ico03 {
  height: 7px;
  margin-top: 2px;
}
iphone .signal .ico04 {
  height: 9px;
}
iphone .fa-feed {
  float: left;
  font-size: 12px !important;
  transform: rotate(-45deg);
  margin-top: 4px;
  margin-right: 8px;
}
iphone .fa-battery-full {
  float: left;
  font-size: 12px !important;
  margin-top: 6px;
}
iphone .fa-chevron-left {
  float: left;
  margin-top: 4px;
}
iphone .fa-cog {
  float: right;
  margin-top: 4px;
}
iphone .btn01 {
  width: 3px;
  height: 28px;
  border-radius: 3px 0 0 3px;
  background: #222;
  position: absolute;
  top: 105px;
  left: -3px;
}
iphone .btn02 {
  width: 3px;
  height: 54px;
  border-radius: 3px 0 0 3px;
  background: #222;
  position: absolute;
  top: 160px;
  left: -3px;
}
iphone .btn03 {
  width: 3px;
  height: 54px;
  border-radius: 3px 0 0 3px;
  background: #222;
  position: absolute;
  top: 230px;
  left: -3px;
}
iphone .btn04 {
  width: 3px;
  height: 86px;
  border-radius: 0 3px 3px 0;
  background: #222;
  position: absolute;
  top: 180px;
  right: -3px;
}
.iframe-content {
  height: calc(100% - 90px);
  margin-top: 64px;
  box-sizing: border-box;
  iframe{
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
